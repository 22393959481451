import Swiper from 'swiper';
import { EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';

// window.Swiper = Swiper;
window.HomeVideoHeaderBanner = () => {
	return {
		swiper: null,
        init() {
            setTimeout(() => {
                this.swiper = new Swiper(this.$refs.swiperContainer, {
                    modules: [ EffectFade, Autoplay ],
					loop: true,
					speed: 500,
                    spaceBetween: 100,
                    effect: 'fade',
					fadeEffect: {
						crossFade: true,
					},
                    slidesPerView: 1,
                    autoplay: {
                        delay: 4000,
                    },
                    a11y: true,
                });
            }, 0);
        },
	};
};
